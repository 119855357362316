<template>
    <section class="content">
        <loading :active.sync="isLoading"
        :color="'#39a2db'"
        :loader="'dots'"
        :is-full-page="true"></loading>
        <div class="row">
            <div class="col-12">
                <div class="container-fluid">
                    <div class="rowx">
                        <div class="text-center text-danger"><h3>{{ formatTime }}</h3></div>
                        <div class="text-center"><h2>Tes Kecermatan - {{tipe}}</h2></div>
                        <div class="text-center text-muted"><h5>Pilih {{tipe}} yang hilang</h5></div>
                        <div class="row">
                            <div class="col-sm-8 offset-sm-2">
                                <table class="table table-bordered text-center">
                                    <thead>
                                        <tr style="background: linear-gradient(90deg, rgb(97, 144, 232) 0%, rgb(167, 191, 232) 100%); color: white; font-weight: 700;">
                                            <td colspan="5">Kolom {{col_index}}</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 56px;" v-html="data_a"></td>
                                            <td style="font-size: 56px;" v-html="data_b"></td>
                                            <td style="font-size: 56px;" v-html="data_c"></td>
                                            <td style="font-size: 56px;" v-html="data_d"></td>
                                            <td style="font-size: 56px;" v-html="data_e"></td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 25px;" >A</td>
                                            <td style="font-size: 25px;" >B</td>
                                            <td style="font-size: 25px;" >C</td>
                                            <td style="font-size: 25px;" >D</td>
                                            <td style="font-size: 25px;" >E</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>

                            <div class="col-sm-8 offset-sm-2">
                                <table class="table table-bordered text-center">
                                    <thead>
                                        <tr>
                                            <td colspan="5" style="font-size: 25px;">SOAL</td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 56px; line-height: 103.52px; letter-spacing: 20px" colspan="5" v-html="soal"></td>
                                        </tr>
                                        <tr>
                                            <td class="clickable-answer" data-id="A" @click="clickedAnswer('A')">A</td>
                                            <td class="clickable-answer" data-id="B" @click="clickedAnswer('B')">B</td>
                                            <td class="clickable-answer" data-id="C" @click="clickedAnswer('C')">C</td>
                                            <td class="clickable-answer" data-id="D" @click="clickedAnswer('D')">D</td>
                                            <td class="clickable-answer" data-id="E" @click="clickedAnswer('E')">E</td>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br>
                </div>
            </div>
        </div>
        <!-- The Right Sidebar -->
        <aside class="control-sidebar control-sidebar-light" style="box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%)">
            <a class="btn btn-small floating-back" data-widget="control-sidebar" href="#"><i class="fas fa-chevron-right"></i></a>
            <div class="row">
                <h5 class="title" style="text-align: center;width: 100%;"><b>Pilih Nomor Soal</b></h5>
            </div>
            <div class="row" style="padding: 16px;">
                <button 
                    v-for="item in menuSoal" 
                    :key="item.id" 
                    v-bind:class="{ 'btn btn-small btn-success': item.selesai, 'btn btn-small btn-primary': item.belum }" 
                    style="margin: 6px;min-height: 36px;"
                    v-on:click="setSoal(item.id)"
                >{{item.id}}</button>
            </div>
        </aside>
        <!-- <a class="floating btn btn-small" data-widget="control-sidebar" href="#"><i class="fas fa-chevron-left"></i></a> -->
    </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import { Plugins } from '@capacitor/core';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import { Network } from '@capacitor/network';

const enable = async () => {
  // await Plugins.PrivacyScreen.enable();
};
export default {
    name: 'SoalProcess',
    data() {
        return {
            kode: '',
            refid: '',
            loading: false,
            no: '',
            pertanyaan: '',
            countdown: 60,
            waktupengerjaan: 0,
            durasipengerjaan : 0,
            pilihan: [],
            showFinish: false,
            enableSkip: true,
            timeout: 0,
            jawab:0,
            menuSoal: [],
            no_soal: '',
            done : '',
            interval: null,
            isLoading: false,
            internet: true,
            sendDataStatus: true,
            sendDataType:'',
            sendDataDirection:'',

            tipe : '',
            col_index : 0,
            data_a : '',
            data_b : '',
            data_c : '',
            data_d : '',
            data_e : '',
            soal : '',
            kecermatan_soal_id_array : [],
            psi_kecermatan_index : '',
            array_col_index_start : [],
            pageReloaded: false ,

            jawaban: [
            ]
        };
    },
    created: function () {
        let id = this.$route.params.index;
        let index = this.$route.params.id;
        this.kode = id;
        this.psi_kecermatan_index = index;
        var is_next_ujian = sessionStorage.getItem('is_next_ujian') ? sessionStorage.getItem('is_next_ujian') : 'sudah_reload';
        if (is_next_ujian == 'reload' && index > 0) {
            sessionStorage.setItem('is_next_ujian', 'sudah_reload');
            this.$router.go();
        }
        this.loadSoal(id);
        enable()
        document.addEventListener('copy', function (event) {
            event.preventDefault();
        });
        document.addEventListener('keydown', function (e) {
            if (e.key === 'F12' || e.keyCode === 123) {
                e.preventDefault();
            }
        });
    },
    mounted(){
        const e = this.$refs;
        let self = this;

        Network.addListener('networkStatusChange', status => {
          // console.log('Network status changed', status);
          // console.log('submit status', this.sendDataStatus);
          this.internet = status.connected
          if (!status.connected) {
            Swal.fire({
                icon: 'info',
                title: 'Tidak ada internet'
            })
          }
          if (!this.sendDataStatus) {
            this.submit(this.sendDataType, this.sendDataDirection)
          }
        });

        // Function to get array index from object value
        function getIndexByValue(array, key, value) {
            for (let i = 0; i < array.length; i++) {
                if (array[i][key] === value) {
                    return i; // Return the index if value is found
                }
            }
            return -1; // Return -1 if value is not found
        }

        this.intervalId = setInterval(() => {
        if (this.waktupengerjaan > 0) {
            this.waktupengerjaan--; // Decrement the countdown
        } else {
            // alert("Countdown finished!");
            // this.tes();
            // console.log(this.array_col_index_start.length)
            if (this.array_col_index_start[this.array_col_index_start.length - 1].col_index == this.col_index) {
                clearInterval(this.intervalId); // Stop the countdown when it reaches zero
                this.submitJawaban()
            } else {
                var index_saat_ini = this.array_col_index_start.find(obj => {
                    return obj.col_index == parseInt(this.col_index) + 1;
                })
                // console.log(this.col_index)
                // console.log(parseInt(this.col_index) + 1)
                // console.log(index_saat_ini)
                // alert('waktu habis, lanjut ke kolom berikutnya')
                
                Swal.fire({
                    title: "Waktu mengerjakan habis",
                    icon: "warning",
                    showCancelButton: false,
                    showConfirmButton: false,
                    timer: 2000
                })
                this.tipe = index_saat_ini.tipe;
                this.col_index = index_saat_ini.col_index;
                this.data_a = index_saat_ini.data_a;
                this.data_b = index_saat_ini.data_b;
                this.data_c = index_saat_ini.data_c;
                this.data_d = index_saat_ini.data_d;
                this.data_e = index_saat_ini.data_e;
                this.soal = index_saat_ini.soal;
                this.waktupengerjaan = this.durasipengerjaan
                this.kecermatan_soal_id_array.push(index_saat_ini.kecermatan_soal_id)
                var soals = JSON.parse(sessionStorage.getItem('soals'))
                var index = getIndexByValue(soals, 'col_index', this.col_index);
                console.log(index)
                sessionStorage.setItem('soal_saat_ini',index)
            }
        }
        }, 1000);
    },
    components: {
        Loading
    },
    methods: {
        loadSoal(id){
            this.loading = true;
            authFetch("/siswa/test/all_soal_kecermatan/" + this.kode +"/"+ this.psi_kecermatan_index).then((res) => {
                res.json().then((json) => {
                    if(json.success)
                    {
                        // var uniqueColIndices = Array.from(new Set(json.data.map(item => item.col_index)));
                        // console.log(uniqueColIndices)
                        var json_data = json.data
                        const firstIndexes = [];

                        json_data.forEach(item => {
                            const colIndex = item.col_index;
                            if (!firstIndexes[colIndex]) {
                                firstIndexes[colIndex] = item;
                            }
                        });
                        var penting = firstIndexes.filter(Boolean)
                        this.array_col_index_start = penting
                        var durasi = json.durasi_kolom * 60;
                        this.durasipengerjaan = durasi;
                        this.waktupengerjaan = durasi;
                        sessionStorage.setItem('soals',JSON.stringify(json.data))
                        sessionStorage.setItem('soal_saat_ini',0)
                        var soals = JSON.parse(sessionStorage.getItem('soals'))
                        var soal_saat_ini = sessionStorage.getItem('soal_saat_ini')
                        var soal_set = soals[soal_saat_ini];
                        this.tipe = soal_set.tipe;
                        this.col_index = soal_set.col_index;
                        this.data_a = soal_set.data_a;
                        this.data_b = soal_set.data_b;
                        this.data_c = soal_set.data_c;
                        this.data_d = soal_set.data_d;
                        this.data_e = soal_set.data_e;
                        this.soal = soal_set.soal;
                        this.kecermatan_soal_id_array.push(soal_set.kecermatan_soal_id)

                        console.log(json)
                    }
                    else{
                        // this.$router.push("/ujian-finish/" + this.kode);
                    }
                });
            });
        },
        submit: function(type, direction = 'next', direct = 0)
        {
            this.isLoading = true;
            this.sendDataType = type;
            this.sendDataDirection = direction;
            this.sendDataStatus = false;

            var formData = {
                'kode' : this.kode,
                'no_soal': this.no,
                'jawab' : this.jawab,
                'ref_id' : this.refid,
                'submitType' : type
            };

            this.loading = true;

            if (this.internet) {
                if(type=='answer' && !this.jawab)
                {
                    if (direction == 'next') {
                        if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                            this.no_soal = parseInt(this.no_soal)+1;
                        } else {
                            this.no_soal = 1;
                        }
                    } else if (direction == 'back') {
                        if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                            this.no_soal = parseInt(this.no_soal)-1;
                        } else {
                            this.no_soal = 1;
                        }
                    } else {
                        this.no_soal = direct;
                    }
                    this.loadSoal(this.kode);
                    return;
                }
                var data = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
                authFetch('/siswa/test/submit', {
                    method: 'POST',
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(json => {
                    this.loading = false;
                    if(json.success)
                    {
                        console.log('oke')
                        this.sendDataStatus = true;
                        this.jawab = '';
                        if(json.data.tampilSemua)this.showFinish=true;
                        if(json.data.sisa<=1)this.enableSkip = false;
                        if(json.data.expired )this.$router.push("/ujian-finish/" + this.kode);// || json.data.endpage
                        else{
                            if(json.data.sisa==0)
                            {
                                // this.no_soal = 1;
                                // this.$router.push("/ujian-finish/" + this.kode)
                                if (direction == 'next') {
                                    if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                                        this.no_soal = parseInt(this.no_soal)+1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else if (direction == 'back') {
                                    if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                                        this.no_soal = parseInt(this.no_soal)-1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else {
                                    this.no_soal = direct;
                                }
                                this.loadSoal(this.kode);
                            }
                            else{
                                if (direction == 'next') {
                                    if (parseInt(this.no_soal)+1 <= this.menuSoal.length) {
                                        this.no_soal = parseInt(this.no_soal)+1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else if (direction == 'back') {
                                    if (parseInt(this.no_soal)-1 <= this.menuSoal.length && parseInt(this.no_soal) > 1) {
                                        this.no_soal = parseInt(this.no_soal)-1;
                                    } else {
                                        this.no_soal = 1;
                                    }
                                } else {
                                    this.no_soal = direct;
                                }
                                this.loadSoal(this.kode);
                            }
                        }
                        //this.$router.push("/ujian-process/" + this.kode);
                    }
                });
            } else {
                Swal.fire({
                    icon: 'info',
                    title: 'Tidak ada internet'
                })
                this.isLoading = false;
                this.loading = false;
                return false;
            }
        },
        setSoal: function(val) {
            this.no_soal = val
            this.submit('answer', 'direct', val)
            // this.loadSoal(this.kode)
        },
        selesai: function() {
            this.$router.push("/ujian-finish/" + this.kode)
        },

        clickedAnswer: function(val){
            var str_soal_saat_ini = parseInt(sessionStorage.getItem('soal_saat_ini'));
            var soals = JSON.parse(sessionStorage.getItem('soals'))
            var jawaban = {
                item: []
            };
            if (str_soal_saat_ini + 1 >= soals.length) {
                console.log('stop')
                var soal_set = soals[sessionStorage.getItem('soal_saat_ini')];
                var data = {
                    'jawaban': val,
                    'col_index': soal_set.col_index,
                    'kecermatan_soal_id' : soal_set.kecermatan_soal_id,
                    'nomor' : soal_set.nomor,
                }
                this.jawaban.push(data);
                sessionStorage.setItem('jawaban',JSON.stringify(this.jawaban))
                console.log(sessionStorage.getItem('jawaban'))
                console.log(this.kecermatan_soal_id_array)
                this.submitJawaban();
            } else {
                sessionStorage.setItem('soal_saat_ini',str_soal_saat_ini  + 1)
                var soal_saat_ini = sessionStorage.getItem('soal_saat_ini')
                var soal_set = soals[soal_saat_ini];
                var jawaban_set = soals[soal_saat_ini - 1]
                if (this.col_index != soal_set.col_index) {
                    // alert('ganti kolom')
                    Swal.fire({
                        title: "Selesai mengerjakan soal",
                        icon: "success",
                        showCancelButton: false,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    this.kecermatan_soal_id_array.push(soal_set.kecermatan_soal_id)
                    this.waktupengerjaan = this.durasipengerjaan
                }
                var data = {
                    'jawaban': val,
                    'col_index': jawaban_set.col_index,
                    'kecermatan_soal_id' : jawaban_set.kecermatan_soal_id,
                    'nomor' : jawaban_set.nomor,
                }
                this.jawaban.push(data);
                sessionStorage.setItem('jawaban',JSON.stringify(this.jawaban))
                console.log(sessionStorage.getItem('jawaban'))
                this.tipe = soal_set.tipe;
                this.col_index = soal_set.col_index;
                this.data_a = soal_set.data_a;
                this.data_b = soal_set.data_b;
                this.data_c = soal_set.data_c;
                this.data_d = soal_set.data_d;
                this.data_e = soal_set.data_e;
                this.soal = soal_set.soal;
                console.log(sessionStorage.getItem('soal_saat_ini'))
            }
        },

        submitJawaban: function() {
            var formData = {
                'data' : sessionStorage.getItem('jawaban'),
                'kecermatan_soal_id' : this.kecermatan_soal_id_array,
                'psi_kecermatan_index' : this.psi_kecermatan_index,
                'ujian_code' : this.kode
            };
            var datas = Object.keys(formData).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(formData[key])).join('&')
            authFetch('/siswa/test/simpan_jawaban_kecermatan', {
                method: 'POST',
                body: datas
            })
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(json => {
                // this.loading = false;
                if(json.success)
                {
                    // console.log(json)
                    // console.log('oke')
                    this.$router.push({ path: '/ujian-break', query: { next_ujian: json.next_ujian,pass_ujian: json.pass_ujian, psi_kecermatan_index: json.psi_kecermatan_index,ujian_code: json.ujian_code } })
                    // this.$router.push("/ujian-break");
                }
            });
        },

        tes: function() {
            console.log('baik')
        }
    },
    computed:{
        getCountdown() {
            var mnt = parseInt(this.countdown/60);
            var dtk = this.countdown%60;
            mnt = mnt > 9 ? mnt : ('0'+mnt);
            dtk = dtk > 9 ? dtk : ('0'+dtk);
            var stat = mnt+':'+dtk;
            if (stat == '03:00') {
                Swal.fire({
                    icon: 'info',
                    title: 'Waktu mengerjakan kurang 3 menit lagi!',
                    text: ''
                })
            }
            if (stat == '00:01') {
                Swal.fire({
                    icon: 'info',
                    title: 'Waktu mengerjakan telah habis',
                    text: 'Silahkan pilih selesai untuk mengakhiri ujian!'
                })
                setTimeout(function () {
                    // this.$router.go = "/ujian-finish/"+this.kode
                }, 1500);
                window.location.href = "/ujian-finish/"+this.kode;
            }
            return this.countdown < 0 ? '-': (mnt+':'+dtk);
        },
        formatTime() {
            const minutes = Math.floor(this.waktupengerjaan / 60);
            const seconds = this.waktupengerjaan % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
    },
}
</script>
<style type="text/css" scoped>
    .floating{
        position:fixed;
        width:36px;
        top:70px;
        right:0px;
        background-color:#0C9;
        color:#FFF;
        /*border-radius:50px;*/
        text-align:center;
        /*box-shadow: 2px 2px 3px #999;*/
    }
    .floating-back{
        width:36px;
        top: 28px;
        left: 16px;
        margin-top: 20px;
        margin-left: -34px;
        background-color:#0C9;
        color:#FFF;
        /*border-radius:50px;*/
        text-align:center;
        /*box-shadow: 2px 2px 3px #999;*/
    }
    .custom-control-input {
        position: absolute;
        left: 0;
        z-index: -1;
        width: 1rem;
        height: 1.25rem;
        opacity: 0;
    }
    .custom-control {
        position: relative;
        z-index: 1;
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5rem;
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
    }
    .custom-control-label::before {
        width: 1.2rem;
        height: 1.2rem;
        left: -2.5rem;
    }
    .custom-control {
        margin-bottom: 6px !important;
    }

    .custom-control-label::after {
        left: -2.5rem;
    }

    .clickable-answer {
        font-size: 25px;
    }

    .clickable-answer:hover {
        background-color: #A7BFE8;
        cursor: pointer;
    }

    .table-bordered > thead > tr > th, .table-bordered > tbody > tr > th, .table-bordered > tfoot > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > td {
        border-color: #6190E8 !important;
    }

    td{
        font-weight: 700;
        border-bottom-width: 1px !important;
    }
</style>